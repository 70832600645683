import "../scss/app.scss";

// SVGs to make into sprite, paths relative to app.js before move
var __svg__ = {
	path: "../svgs/sprite/*.svg",
	name: "../../echoview/jinja2/svgs/sprite.svg"
};

// Production code goes after here
// import { ValidatedForm } from "@neonjungle/birdseed/forms/validatedform";
// import { OutdatedBrowserNotice } from "@neonjungle/birdseed/browser/OutdatedBrowserNotice";

import menuActions from "./includes/menuActions";
import attributesForCSS from "./includes/attributesForCSS";
import initAccordions from "./includes/accordion";

document.addEventListener("DOMContentLoaded", function() {
	menuActions();
	attributesForCSS();
	initAccordions(true);

	// new OutdatedBrowserNotice(false, browserOutdated => {
	// 	if (browserOutdated) {
	// 		document.body.classList.add("browser-is-outdated");
	// 	}
	// });

	document.body.classList.remove("preload");

	// const validatedForms = document.querySelectorAll("form.l-form");
	// validatedForms.forEach($f => new ValidatedForm($f));
});
