let globalsDone = false;

export default function initAccordions(setupHandlers) {
    let accordionsEls = document.querySelectorAll("[data-accordion]");

    if (accordionsEls.length > 0) {
        accordionsEls.forEach($acc => {
            let $triggers = $acc.querySelectorAll("[data-accordion-trigger]")
            $triggers.forEach($t => {
                const contentId = $t.getAttribute("aria-controls");
                const $content = $acc.querySelector(`#${contentId}`);
                setAccordionHeights($content);
                if (setupHandlers) clickHandlers($t, $content);
            })
        })
        if (!globalsDone) {
            globalsDone = true;
            window.addEventListener("resize", _ => {
                initAccordions(false);
            });
        }
    }
}

function setAccordionHeights($content) {
    const opened = $content.parentNode.classList.contains("is-active");
    $content.style.setProperty("--accordion-height", "auto");

    $content.classList.remove("is-closed");

    $content.style.setProperty(
        "--accordion-height",
        $content.offsetHeight + "px"
    );
    if (!opened) {
        $content.classList.add("is-closed");
    } else {

    }
}

function clickHandlers($trigger, $content) {
    $trigger.addEventListener("click", e => {
        const ariaHiddenValue = attrBool($content.getAttribute("aria-hidden"));
        const ariaExpandedValue = attrBool($trigger.getAttribute("aria-expanded"));

        $trigger.parentNode.classList.toggle("is-active");
        $trigger.setAttribute("aria-expanded", !ariaExpandedValue);
        $content.setAttribute("aria-hidden", !ariaHiddenValue);
        $content.classList.toggle("is-closed");
        e.preventDefault();
    });
}

function attrBool(attrValue) {
    return attrValue === "true" ? true : false;
}
